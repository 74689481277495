import { NextPage } from 'next';

import { LoginPage } from '../components';

const LoginNextPage: NextPage = () => {
  return <LoginPage />;
};

// eslint-disable-next-line import/no-default-export
export default LoginNextPage;
